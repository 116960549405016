import React from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import tick from '../images/tick.svg';
import activeTick from '../images/active-tick.svg';

const StepsModal = ({
  isOpen,
  onClose,
  customStyles,
  stateOfrawTranscation,
  stateOfpayment,
  transactionHash
}) => {
  return (
<Modal
  style={customStyles}
  isOpen={isOpen}
  onRequestClose={onClose}
>
<div className="setps-modal">
  <h4 className='steps-head'>Following steps</h4>
  <div className='list-wrapper'>
      <div className="steps-wrap">
      <div className="steps-media">
        {stateOfrawTranscation === 'waiting' &&
          <span className="tick">
          <img src={tick} alt="tick"/>
          </span>
        }
        {stateOfrawTranscation === 'getting' &&
          <div className="loader" />
        }
        {stateOfrawTranscation === 'done' &&
        <span className="tick">
          <img src={activeTick} alt="tick"/>
        </span>
        }
      </div>
      <div className="steps-infor">
        <h4>Initiating</h4>
        <h5>Getting raw transaction for you!</h5>
      </div>
      </div>

      <div className="steps-wrap">
      <div className="steps-media">
        {stateOfpayment === 'waiting' &&
          <span className="tick">
          <img src={tick} alt="tick"/>
          </span>
        }
        {stateOfpayment === 'getting' &&
          <div className="loader" />
        }
        {stateOfpayment === 'done' &&
        <span className="tick">
          <img src={activeTick} alt="tick"/>
        </span>
        }
      </div>
      <div className="steps-infor">
        <h4>Payment & Mint</h4>
        <h5>Waiting for you to initiate the payment and <br /> sumbit your transaction to create your NFT!</h5>
      </div>
      </div>
  </div>
  {transactionHash && (<div className='linktoexplorer'>
    <Link to={`https://etherscan.io/tx/${transactionHash}`} target='_blank'>Check on Explorer</Link>
  </div>
  )}
</div>
</Modal>
);
}

export default StepsModal;
