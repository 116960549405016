import { request } from './api';
const API_URL = {
    PRE_LOGIN: 'user/get-create',
    LOGIN: 'user/login',
    TRANSACTION:'transaction',
    SET_TRANSACTION:'transaction/add-trancation-hash'
}
const HTTP_METHODS = {
    POST: 'POST',
    GET: 'GET',
    PUT: 'PUT',
    PATCH: 'PATCH',
    DELETE: 'DELETE'
};

export function preLogin(data) {
  return request(HTTP_METHODS.POST, API_URL.PRE_LOGIN, data);
}

export function login(data) {
    return request(HTTP_METHODS.POST, API_URL.LOGIN, data);
}

export function getTransaction(params) {
    return request(HTTP_METHODS.GET, API_URL.TRANSACTION, {}, params);
}

export function setTransactionHash(data) {
    return request(HTTP_METHODS.POST, API_URL.SET_TRANSACTION, data);
}

export const authService = {
preLogin,
login,
getTransaction
};
