import './css/style.css';
import { Routes, Route } from "react-router-dom";
import Cookies from 'js-cookie';
import configureAxios from './core-helper/configAxios';
import Home from "./pages/Home";
import Team from "./pages/Team";
import { WagmiConfig, createConfig, configureChains, mainnet } from 'wagmi';
import { infuraProvider } from "wagmi/providers/infura";
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { setTokenHeader } from './service/api';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
window.Buffer = window.Buffer || require("buffer").Buffer;

const { chains } = configureChains(
  [mainnet],
  [
    infuraProvider({ apiKey: 'f6dfe8d5dd0e40418557d079f528a345' }),
  ]
);

configureAxios();

if (Cookies.get('token')) {
  setTokenHeader(Cookies.get('token'));
}
 
const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: '54daf1e2095f67d013ef64054b4e4337',
      },
    })
  ]
})

function App() {
  return (
    <WagmiConfig config={config}>
      <div className="App">
        <Routes>
          <Route path="/" element={ <Home/> } />
          <Route path="team" element={ <Team/> } />
        </Routes>
      </div>
    </WagmiConfig>
  )
}

export default App;
