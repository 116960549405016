import axios from 'axios';
import Cookies from 'js-cookie';

const configureAxios = () => {
const REACT_APP_API_ROOT="https://api.psyopzombies.com/api/"
//  const REACT_APP_API_ROOT= "http://localhost:8080/api/"
  axios.defaults.baseURL = REACT_APP_API_ROOT;
  axios.defaults.headers.common.Authorization = Cookies.get("token") ? Cookies.get("token") : null;
  axios.defaults.headers.patch['Content-Type'] = 'application/json';
  
  axios.interceptors.response.use(
    response => response,
    error => {
      const status = error.response ? error.response.status : null;
      if (status === 401) {
        Cookies.set("token", "");
      }
      return Promise.reject(error);
    }
  );

  axios.interceptors.request.use(
    config => {
      return config;
    },
    error => Promise.reject(error)
  );
};

export default configureAxios;
