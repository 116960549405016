import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';
import Modal from 'react-modal';
import bannerImgGif from '../images/banner-img.gif';
import bubblemapV2 from '../images/bubble-map-V2.png';
import adlookalike from '../images/ad-lookalike.png';
import AdLookalike2 from '../images/Ad-Lookalike-2.png';
import { 
  useAccount,
  useConnect,
  useDisconnect,
  useSignMessage,
  useSendTransaction,
  useSwitchNetwork,
  useNetwork
} from 'wagmi';
import { preLogin, login, getTransaction, setTransactionHash } from '../service/authMintService';
import Cookies from 'js-cookie';
import configureAxios from '../core-helper/configAxios';
import StepsModal from '../components/stepModal';
function toggleMenu() {
  var toggleMenu = document.getElementById("toggleMenu");
  let navBar = document.getElementById("navBar");
  toggleMenu.classList.toggle("open");
  navBar.classList.toggle("show");
}

function Home() {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  const [modalIsOpen, setIsOpen] = useState(false);
  const [actionModal, setActionModal] = useState(false);
  const [isTryingLogin, setIsTryingLogin] = useState(false);
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const [amountOfNFT, setAmountOfNFT] = useState(1);
  const [itemPicked, setItemPicked] = useState([]);
  const [stateOfrawTranscation, setStateOfrawTranscation] = useState('waiting');
  const [stateOfpayment, setStateOfpayment] = useState('waiting');

  const {address, isConnected } = useAccount();
  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect();
    const { data, signMessage } = useSignMessage();
  const { disconnect } = useDisconnect();

  const { chain } = useNetwork();
  const { switchNetwork } =
    useSwitchNetwork();
  
  useEffect(() => {
    if (chain && chain?.id !== 1) {
      switchNetwork(1);
    }
  }, [chain]);
  
  useEffect(() => {
    if (isConnected && Cookies.get('token')) {
      setIsOpen(false);
      setIsTryingLogin(false);
      setisLoggedIn(true);
      configureAxios();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, Cookies.get('token')])

  useEffect(() => {
    async function setLoginData () {
      if (isConnected && !isLoggedIn && isTryingLogin ) {
        setIsOpen(false);
        setIsTryingLogin(false);
        try {
        const res = await preLogin({userWalletAddress: address});
        await signMessage({
        message: res.messageToSign
        });
        } catch (err) {
          console.log("error", err);
        }
      }
    }
    setLoginData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, isTryingLogin, isLoggedIn]);

  useEffect(() => {
    async function setLoginData () {
      if (isConnected && data && !isLoggedIn ) {
        try {
        const res = await login({userWalletAddress: address, signature: data});
        Cookies.set('token', res.data.token ? res.data.token : '');
        setisLoggedIn(true);
        setIsTryingLogin(false);
        } catch (err) {
          console.log("error", err);
        }
      }
    }
    setLoginData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

const { data:transactionData, error: ErrorInSendTranscation,sendTransaction } = useSendTransaction({});

useEffect(() => {
 if (transactionData) {
  const body = {
    _id: itemPicked,
    hash: transactionData.hash
  }
  setTransactionHash(body);
  setStateOfpayment('done');
 }
 if (ErrorInSendTranscation) {
  setActionModal(false);
  setStateOfrawTranscation('waiting');
  setStateOfpayment('waiting');
  alert(ErrorInSendTranscation);
 }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [transactionData, ErrorInSendTranscation])

  const getBlockchainTransactoin = async () => {
    try {
    setActionModal(true);
    setStateOfrawTranscation('getting');
    const res = await getTransaction({amountOfNFT});
    setStateOfrawTranscation('done');
    setItemPicked(res.data.itemPicked);
    setStateOfpayment('getting');
    sendTransaction(res.data.transaction);
    } catch (err) {
       alert(err.message);
    }
  };

  const loginIntitate = () => {
    Cookies.remove("token");
    setIsOpen(true); 
    setIsTryingLogin(true);
  }

  const increaseCount = () => {
    if (amountOfNFT < 50) {
      setAmountOfNFT(pre => pre + 1);
    }
  }

  const decreaseCount = () => {
    if (amountOfNFT > 1) {
      setAmountOfNFT(pre => pre -1);
    }
  }

    return (
      <>
    <div className="banner-section">
      <div className="container">
        <header className="header">
          <div className="mobile-toggle">
            <button id="toggleMenu" className="toggle-menu" onClick={toggleMenu} > </button>
          </div>
          <div className="logo">
            <Link to="/"> <img src={logo} alt="PSYOP ZOMBIE Logo" /> </Link>
            { (isConnected && isLoggedIn) && (
              <div className='walletConnected'>
              <span>{address}</span>
              <button className="primary-btn" onClick={()=> {
                Cookies.remove("token");
                disconnect();
              }
                }> disconnect</button>
            </div>)}
          </div>
          <nav id="navBar" className="navbar">
            <ul>
              <li> <Link to="/team"> Team </Link> </li>
            </ul>
          </nav>
        </header>
        <div className="banner-content">
          <div className="animated-img">
            <img src={bannerImgGif} alt="Banner Img" />
          </div>
          <div className="text">
            <p> ZOMBIE VIRUS UNLEASHED, MINTING NOW!</p>
            <p> 10,000 PSYOP ZOMBIES IN THE COLLECTION</p>
            <p> 100% ANTI-RUG PULL, 100% ANTI SCAM </p>
            <p> 100% DOPE MEME NFT ART </p>
            <p> CONTRACT ADDRESS <Link className='none_style_CRP' target='_blank' to={"https://etherscan.io/address/0x7755ed489b1ab19bffd8039de8331166214d7509"}>OX7…09</Link></p>
            <p> MINT DETAILS 
              <span className='cusrorPointer' onClick={decreaseCount}> -</span> / <span className='cusrorPointer' onClick={increaseCount}>+ </span> 
                ({ amountOfNFT*0.008}ETH) 
            </p>
          </div>
          {(isConnected && isLoggedIn) ? (
          <button className="primary-btn" onClick={getBlockchainTransactoin}>{ amountOfNFT === 1 ? 'Mint' : `Mint qty:${amountOfNFT}`}</button>
          ):(
          <button className="primary-btn" onClick={loginIntitate}> Connect wallet to Mint</button>
          )}
        </div>
      </div>
    </div>
    
    {/* <div className="economy-section">
      <div className="container">
        <h2> Welcome to the attention economy </h2>
        <div className="row">
          <div className="column-7">
            <div className="bubble-map-img">
              <img src={bubblemapV2} alt="" />
            </div>
          </div>
          <div className="column-5">
            <div className="ad-img">
              <img src={adlookalike} alt="Ad images" />
            </div>
          </div>
        </div>
      </div>
    </div> */}
    <div className="zombie-need-section">
      <div className="container">
        <h2> ZOMBIES NEED TO EAT TOO! </h2>
        <p> THE FULL UTILITY OF THE PSYOP ZOMBIES NFTS WILL BE REVEALED. </p>
        <p> AS A START, PSYOP ZOMBIE NFT HOLDERS WILL BE ON THE WHITELIST</p>
        <p>FOR FRIENDS & FAMILY ROUND FOR  WWW.ZAG.NETWORK, </p>
        <p>A SOCIAL NETWORK BUILT ON WEB3 TECHNOLOGY </p>
        <p>THE DEVS ARE COOKING. </p>
        <p>BETA TESTING PROJECTED TO BE IN AUGUST 2023</p>
      </div>
    </div>
    <div className="term-fo-use-section">
      <div className="container">
        <div className="terms-heading">
          <h2> Terms of use </h2>
        </div>
       <div className="row">
          <div className="column-9">
            <div className="terms-description">
              <p> Information provided on this site is for general educational purposes only and is not intended to constitute
                investment or other advice on financial products. Such information is not, and should not be read as, an offer
                or recommendation to buy or sell or a solicitation of an offer or recommendation to buy or sell any particular
                digital asset or to use any particular investment strategy. </p>
              <p> PSYOP ZOMBIES makes no representations as to the accuracy, completeness, timeliness, suitability, or validity
                of any information on this site and will not be liable for any errors, omissions, or delays in this information
                or any losses, injuries, or damages arising from its display or use. Unless otherwise noted, all images are the
                property of their respective IP owners. Links provided to third-party sites are for informational purposes. Such
                sites are not under the control of PSYOP ZOMBIES, and PSYOP ZOMBIES is not responsible for the accuracy of the
                content on such third-party sites. </p>
            </div>
          </div>
          <div className="column-3">
            <img src={AdLookalike2} alt="" />
          </div>
       </div>
      </div>
    </div>
    <footer className="footer">
      <div className="container">
        <div className="copyright-text">
          <p> Copyright © Monini 2023. All Rights Reserved </p>
        </div>
      </div>
    </footer>
    <Modal
        isOpen={modalIsOpen}
        onRequestClose={()=> {setIsTryingLogin(false); setIsOpen(false);}}
        style={customStyles}
        contentLabel="Wallet connect!"
      >
        <div className='walletConnectOptionsList'>
          {connectors.map((connector) => (
          <button
          disabled={!connector.ready}
          key={connector.id}
          onClick={() => connect({ connector })}
          >
          {connector.name}
          {!connector.ready && ' (unsupported)'}
          {isLoading &&
            connector.id === pendingConnector?.id &&
            ' (connecting)'}
          </button>
          ))}

          {error && <div className='Error_message'>{error.message}</div>}
        </div>
    </Modal>
    <StepsModal 
      stateOfrawTranscation={stateOfrawTranscation}
      stateOfpayment={stateOfpayment}
      onClose={() => setActionModal(false)}
      isOpen={actionModal}
      customStyles={customStyles}
      transactionHash={transactionData?.hash}
    />
    </>
    );
  }

export default Home;
