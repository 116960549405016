import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

const DEFAULT_HTTP_ERROR_MESSAGE = 'Something went wrong. Please try again.';

const API_STATUS_CODE = {
    TOKEN_EXPIRED: 401,
    SUCCESS: 200,
    NOT_FOUND: 404
};

const ErrorToast = ({ message }) => (
    <div className="alert alert-danger">
      <span className="alert-text">{message}</span>
      <div className="close">
        <i className="icon-cross" />
      </div>
    </div>
);

const errorToast = (id, message) => {
    toast.clearWaitingQueue();
    toast.dismiss(id);
    toast.error(<ErrorToast message={message} />, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000,
      hideProgressBar: true,
      closeButton: true,
      toastId: id
    });
    window.scrollTo(0, 0);
};

export function setTokenHeader(token) {
  if (token) {
    axios.defaults.headers.common.Authorization = Cookies.get('token');
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
}

export function request(method, url, data, params, headers = {}) {
  return new Promise((resolve, reject) => axios({
    method,
    url,
    data,
    params,
    headers
  })
    .then(res => resolve(res.data))
    .catch(err => {
      if (!err.response) {
        errorToast(1, DEFAULT_HTTP_ERROR_MESSAGE);
      } else if (err.response?.status === API_STATUS_CODE.TOKEN_EXPIRED) {
          errorToast(1, err.response?.data.message);
      }
      return reject(
        err.response
          ? err.response.data
          : { message: DEFAULT_HTTP_ERROR_MESSAGE }
      );
    }));
}