import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';
import teamm2 from '../images/team-m2.jpg';
import teamm1 from '../images/team-m1.jpg';

function toggleMenu() {
  var toggleMenu = document.getElementById("toggleMenu");
  let navBar = document.getElementById("navBar");
  toggleMenu.classList.toggle("open");
  navBar.classList.toggle("show");
}

function Team() {
    return (
       <>
         <div className="banner-section">
    <div className="container">
      <header className="header">
        <div className="mobile-toggle">
          <button id="toggleMenu" className="toggle-menu" onClick={toggleMenu}> </button>
        </div>
        <div className="logo">
          <Link to="/"> <img src={logo} alt="PSYOP ZOMBIE Logo" /> </Link>
        </div>
        <nav className="navbar" id="navBar">
          <ul>
            <li> <Link className="active" to="/team"> Team </Link> </li>
          </ul>
        </nav>
      </header>
      <div className="meet-out-team">
        <h1> Meet the team </h1>
        <div className="row">
          <div className="column-4">
            <div className="team-item">
              <div className="member-img">
                <img src={teamm1} alt="" />
              </div>
              <h6> Satoshi monini </h6>
              <p> Alpha dog</p>
            </div>
          </div>
          <div className="column-4">
            <div className="team-item">
              <div className="member-img">
                <img src={teamm2} alt="" />
              </div>
              <h6> Hachiko nakamoto </h6>
              <p> Dev </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <footer className="footer">
    <div className="container">
      <div className="copyright-text">
        <p> Copyright © Monini 2023. All Rights Reserved </p>
      </div>
    </div>
  </footer>
       </>
    )
}

export default Team;
